import React from 'react';

import { Box } from '@core';
import { FeaturesAccordion } from '@containers';

const Accordion = ({ title, description, button, link, data, padding, showGuarantee }) => (
  <Box {...padding}>
    <FeaturesAccordion
      title={title}
      description={description}
      button={button}
      link={link}
      data={data}
      showGuarantee={showGuarantee}
    />
  </Box>
);

export default Accordion;
